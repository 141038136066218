<template>
  <div class="pt-60 pb-24">

    <div class="container mx-auto">
      <h1 class="custom-heading h-custom-color">Na stiahnutie</h1>
      <p class="p-custom-color custom-paragraph mr-20">
        Posledná aktualizácia 21. decembra 2021
      </p>
      <p class="mt-20 text-2xl">
        <a href="/assets/files/hostnow-logo-assets.zip" class="text-blue-800 hover:underline">Súbory loga HostNow.cz</a>
        <br>
        <a href="/assets/files/hostnow-design-manual.pdf" target="_blank" class="text-blue-800 hover:underline">Design manuál</a>
      </p>

      <br>



    </div>

  </div>
</template>

<script>

export default {
  components: {
  },
  mounted () {
    window.scrollTo(0, 0)
  },

  metaInfo() {
    return {title: "Na stiahnutie"}
  },
}
</script>

<style scoped>

</style>